const data = [
    "/dashboard", 
    "/mobile/menu", 
    "/edit/mobile/menu/:id", 
    "/add/mobile/menu", 
    "/list/parameter",
    "/add/parameter",
    "/edit/parameter/:id",
    "/role", 
    "/add/role",
    "/edit/role/:id",
    "/user/admin", 
    "/user/admin/add ", 
    "/user/lks/add",
    "/user/admin/nazhir", 
    "/user/nazhir/add",
    "/program/list", 
    "/approval/unggah/program", 
    "/activity", 
    "/approval/edit/program", 
    "/milestone", 
    "/batch1", 
    "/add/batch/:id",
    "/detail/batch/:batchId/:id", 
    "/batch4", 
    "/batch3", 
    "/layanan", 
    "/events",
    "/add/event",
    "/edit/event/:id",
    "/carousel",
    "/add/carousel",
    "/edit/carousel/:id",
    "/penghimpundana",
    "/totalprogram",
    "/totalnazhir",
    "/totalwakif",
    "/pengelolaanwakaf",
    "/swu",
    "/tps",
    "/amalan",
    "/wakaf-program",
    "/detail/wakaf-program/:id",
    "/update/detail/:id",
    "/update/activity/:id",
    "/update/milestone/:id",
    "/create/wakaf-program",
    "/category/wakaf",
    "/add/category/wakaf",
    "/edit/category/wakaf/:id",
    "/category/zakat",
    "/add/category/zakat",
    "/edit/category/zakat/:id",
    "/program/zakat",
    "/create/program/zakat",
    "/chargetypes",
    "/add/chargetypes",
    "/edit/chargetypes/:id",
    "/paymentmethod",
    "/add/paymentmethod",
    "/edit/paymentmethod/:id",
    "/edit/bankmerchant/:id",
    "/lembaga",
    "/assets",
    "/create/asset",
    "/edit/asset/:id",
    "/detail/asset/:id",
    "/report/assets",
    "/report/kpi",
    "/report/transaction",
    "/asset/history/:id",
    "/other-project",
    "/create/other-project",
]

export default data