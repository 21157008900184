const initialState = {
    session: {}
}

const session = (state = initialState, action) => {
    switch (action.type) {
        case "SESSION_DATA":
            return state = { session: action.payload }
      
        default: return state
    }
}

export default session