import { useMemo, useState, useEffect } from 'react'
import Router from './router'
import { Provider } from 'react-redux'
import store from './redux'
import { createMuiTheme } from '@material-ui/core/styles'
import { ThemeProvider } from '@material-ui/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'

export default () => {
    const [darkMode, setDarkMode] = useState(false)
    
    // const theme = useMemo(
    //     () => createMuiTheme({
    //         palette: {
    //             type: darkMode ? 'dark' : 'light',
    //             contrastThreshold: window._env_.APP_CONTRAST_THRESHOLD,
    //             tonalOffset: window._env_.APP_TONAL_OFFSET,
    //             primary: {
    //                 main: window._env_.APP_PRIMARY_COLOR_MAIN,
    //                 light: window._env_.APP_PRIMARY_COLOR_LIGHT,
    //                 dark: window._env_.APP_PRIMARY_COLOR_DARK
    //             },
    //         },
    //     }),
    //     [darkMode]
    // )

    useEffect(() => {
        var root = document.querySelector(':root')
        root.style.setProperty('--primary', process.env.REACT_APP_PRIMARY_COLOR_MAIN)
        root.style.setProperty('--primary-light', process.env.REACT_APP_PRIMARY_COLOR_LIGHT)
        root.style.setProperty('--primary-dark', process.env.REACT_APP_PRIMARY_COLOR_DARK)
    }, [])

    return (
        <Provider store={store}>
            <Router setDarkMode={setDarkMode} />
        </Provider>
    )
}