import { BrowserRouter as Router, Route, Redirect, Switch, HashRouter } from "react-router-dom";
import AppRoute from './appRoute'
import { lazy, Suspense, useEffect, useState } from 'react'
import Logo from 'assets/img/logo.png'
import { getLocation, prayTimes } from 'mixin'
import { useSelector, useDispatch } from 'react-redux'
import listUrl from './listUrl'
import moment from 'moment'

const Login = lazy(() => import('apps/login'))
const FullLayout = lazy(() => import('layouts/Full'))
const MainLayout = lazy(() => import('layouts/Main'))
const Dashboard = lazy(() => import('apps/dashboard'))
const MobileMenu = lazy(() => import('apps/parameter/mobile_menu/list'))
const FormMobileMenu = lazy(() => import('apps/parameter/mobile_menu/form'))
const Role = lazy(() => import('apps/role'))
const RoleForm = lazy(() => import('apps/role/form'))
const UserAdmin = lazy(() => import('apps/user/admin'))
const UserAdminAdd = lazy(() => import('apps/user/admin/components/user_admin_add'))
const UserLKS = lazy(() => import('apps/user/lks'))
const UserLKSAdd = lazy(() => import('apps/user/lks/components/user_lks_add'))
const UserNazhir = lazy(() => import('apps/user/nazhir'))
const UserNazhirAdd = lazy(() => import('apps/user/nazhir/components/user_nazhir_add'))
const ProgramList = lazy(() => import('apps/program/list'))
const ApprovalUnggahProgram = lazy(() => import('apps/program/approval_unggah_program'))
const ApprovalEditProgram = lazy(() => import('apps/program/approval_edit_program'))
const Activity = lazy(() => import('apps/program/activity'))
const Milestone = lazy(() => import('apps/program/milestone'))
const AutoDebitHC = lazy(() => import('apps/batch_upload/auto_debit_hc'))
const AddBatch = lazy(() => import('apps/batch_upload/add'))
const BatchUploadDetail = lazy(() => import('apps/batch_upload/detail'))
const AutoDebitHambaAllah = lazy(() => import('apps/batch_upload/auto_debit_hamba_allah'))
const BlastEmail = lazy(() => import('apps/batch_upload/blast_email'))
const Layanan = lazy(() => import('apps/layanan'))
const Events = lazy(() => import('apps/events/list'))
const FormEvent = lazy(() => import('apps/events/form'))
const Carousel = lazy(() => import('apps/carousel/list'))
const FormCarousel = lazy(() => import('apps/carousel/form'))
const PenghimpunDana = lazy(() => import('apps/reports/penghimpundana'))
const TotalProgram = lazy(() => import('apps/reports/totalprogram'))
const TotalNazhir = lazy(() => import('apps/reports/totalnazhir'))
const TotalWakif = lazy(() => import('apps/reports/totalwakif'))
const PengelolaanWakaf = lazy(() => import('apps/reports/pengelolaanwakaf'))
const SWU = lazy(() => import('apps/reports/swu'))
const Tps = lazy(() => import('apps/reports/tps'))
const Amalan = lazy(() => import('apps/reports/amalan'))
const WakafProgram = lazy(() => import('apps/wakaf_program/list'))
const DetailAsset = lazy(() => import('apps/wakaf_program/detail'))
const UpdateDetail = lazy(() => import('apps/wakaf_program/updateDetail'))
const UpdateActivity = lazy(() => import('apps/wakaf_program/updateActivity'))
const UpdateMilestone = lazy(() => import('apps/wakaf_program/updateMilestone'))
const CreateProgram = lazy(() => import('apps/wakaf_program/createProgram'))
const ListParameter = lazy(() => import('apps/parameter/parameter/list'))
const FormParameter = lazy(() => import('apps/parameter/parameter/form'))
const WakafCategory = lazy(() => import('apps/category_wakaf'))
const WakafCategoryForm = lazy(() => import('apps/category_wakaf/form'))
const ZakatCategory = lazy(() => import('apps/category_zakat'))
const ZakatCategoryForm = lazy(() => import('apps/category_zakat/form'))
const ZakatProgram = lazy(() => import('apps/projectZakat'))
const CreateProgramZakat = lazy(() => import('apps/projectZakat/form'))
const ChargeTypes = lazy(() => import('apps/chargetypes'))
const FormChargeTypes = lazy(() => import('apps/chargetypes/form'))
const PaymentMethod = lazy(() => import('apps/paymentMethod'))
const FormPaymentMethod = lazy(() => import('apps/paymentMethod/form'))
const EditBankMerchant = lazy(() => import('apps/user/nazhir/components/edit_bank_merchant'))
const Lembaga = lazy(() => import('apps/lembaga'))
const Assets = lazy(() => import('apps/assets/list'))
const FormAsset = lazy(() => import('apps/assets/form'))
const DetailAssets = lazy(() => import('apps/assets/detail'))
const AssetHistory = lazy(() => import('apps/assets/history'))
const ReportAssets = lazy(() => import('apps/reports/assets'))
const ReportKPI = lazy(() => import('apps/reports/kpi'))
const ReportTransaction = lazy(() => import('apps/reports/transaction'))

const Loading = () => {
    return (
        <div className="w-full h-screen flex justify-center">
            <div className="self-center">
                <img src={process.env.REACT_APP_API_URL + '/' + process.env.REACT_APP_COMPANY_LOGO} alt="logo" className="w-3/4" />
            </div>
        </div>
    )
}

export default (props) => {
    
    return (
        <Router>
            <Switch>
                <Suspense fallback={<Loading />}>
                    <Route path="/" exact layout={FullLayout} component={Login} />
                    <Route path={listUrl}>
                        <MainLayout>
                            <Switch>
                                <Route path="/dashboard" component={Dashboard} />
                                <Route path="/mobile/menu" component={MobileMenu} />
                                <Route path="/edit/mobile/menu/:id" component={FormMobileMenu} />
                                <Route path="/add/mobile/menu" component={FormMobileMenu} />
                                <Route path="/list/parameter" component={ListParameter} />
                                <Route path="/add/parameter" component={FormParameter} />
                                <Route path="/edit/parameter/:id" component={FormParameter} />
                                <Route path="/role" component={Role} />
                                <Route path="/edit/role/:id" component={RoleForm} />
                                <Route path="/add/role" component={RoleForm} />
                                <Route path="/user/admin" exact  component = {UserAdmin} />
                                <Route path="/user/admin/add" component = {UserAdminAdd} />
                                <Route path="/user/admin/lks" component = {UserLKS} />
                                <Route path="/user/lks/add" component = {UserLKSAdd} />
                                <Route path="/user/admin/nazhir" component={UserNazhir}/>
                                <Route path="/user/nazhir/add" component = {UserNazhirAdd} />
                                <Route path="/program/list" component={ProgramList}/>
                                <Route path="/approval/unggah/program" component={ApprovalUnggahProgram}/>
                                <Route path="/approval/edit/program" component={ApprovalEditProgram}/>
                                <Route path="/activity" component={Activity}/>
                                <Route path="/milestone" component={Milestone}/>
                                <Route path="/batch1" component={AutoDebitHC}/>
                                <Route path="/add/batch/:id" component={AddBatch}/>
                                <Route path="/detail/batch/:batchId/:id" component={BatchUploadDetail}/>
                                <Route path="/batch4" component={AutoDebitHambaAllah}/>
                                <Route path="/batch3" component={BlastEmail}/>
                                <Route path="/layanan" component={Layanan}/>
                                <Route path="/events" component={Events}/>
                                <Route path="/add/event" component={FormEvent}/>
                                <Route path="/edit/event/:id" component={FormEvent}/>
                                <Route path="/carousel" component={Carousel}/>
                                <Route path="/add/carousel" component={FormCarousel}/>
                                <Route path="/edit/carousel/:id" component={FormCarousel}/>
                                <Route path="/penghimpundana" component={PenghimpunDana}/>
                                <Route path="/totalprogram" component={TotalProgram}/>
                                <Route path="/totalnazhir" component={TotalNazhir}/>
                                <Route path="/totalwakif" component={TotalWakif}/>
                                <Route path="/pengelolaanwakaf" component={PengelolaanWakaf}/>
                                <Route path="/swu" component={SWU}/>
                                <Route path="/tps" component={Tps}/>
                                <Route path="/amalan" component={Amalan}/>
                                <Route path="/wakaf-program" component={WakafProgram}/>
                                <Route path="/other-project" component={WakafProgram}/>
                                <Route path="/detail/wakaf-program/:id" component={DetailAsset}/>
                                <Route path="/update/detail/:id" component={UpdateDetail}/>
                                <Route path="/update/activity/:id" component={UpdateActivity}/>
                                <Route path="/update/milestone/:id" component={UpdateMilestone}/>
                                <Route path="/create/wakaf-program" exact component={CreateProgram}/>
                                <Route path="/create/other-project" exact component={CreateProgram}/>
                                <Route path="/category/wakaf" component={WakafCategory}/>
                                <Route path="/add/category/wakaf" component={WakafCategoryForm}/>
                                <Route path="/edit/category/wakaf/:id" component={WakafCategoryForm}/>
                                <Route path="/category/zakat" component={ZakatCategory}/>
                                <Route path="/add/category/zakat" component={ZakatCategoryForm}/>
                                <Route path="/edit/category/zakat/:id" component={ZakatCategoryForm}/>
                                <Route path="/program/zakat" component={ZakatProgram}/>
                                <Route path="/create/program/zakat" exact component={CreateProgramZakat}/>
                                <Route path="/chargetypes" component={ChargeTypes}/>
                                <Route path="/add/chargetypes" component={FormChargeTypes}/>
                                <Route path="/edit/chargetypes/:id" component={FormChargeTypes}/>
                                <Route path="/paymentmethod" component={PaymentMethod}/>
                                <Route path="/add/paymentmethod" component={FormPaymentMethod}/>
                                <Route path="/edit/paymentmethod/:id" component={FormPaymentMethod}/>
                                <Route path="/edit/bankmerchant/:id" component={EditBankMerchant}/>
                                <Route path="/lembaga" component={Lembaga}/>
                                <Route path="/assets" component={Assets}/>
                                <Route path="/create/asset" component={FormAsset}/>
                                <Route path="/detail/asset/:id" component={DetailAssets}/>
                                <Route path="/asset/history/:id" component={AssetHistory}/>
                                <Route path="/edit/asset/:id" component={FormAsset}/>
                                <Route path="/report/assets" component={ReportAssets}/>
                                <Route path="/report/kpi" component={ReportKPI}/>
                                <Route path="/report/transaction" component={ReportTransaction}/>
                            </Switch>
                        </MainLayout>
                    </Route>
                </Suspense>
            </Switch>
        </Router>
    )
}
