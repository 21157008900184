const initialState = {
    company: {}
}

const baseData = (state = initialState, action) => {
    switch (action.type) {
        case "COMPANY_DATA":
            return state = { company: action.payload }
      
        default: return state
    }
}

export default baseData